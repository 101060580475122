import { useCookies } from 'react-cookie';

const useSession = () => {
  const [ cookies, setCookie, removeCookie ] = useCookies([ 'token' ]);

  const setToken = (value: string) => {
    setCookie('token', value, {
      path: '/',
    });
  };

  const destroySession = () => {
    removeCookie('token', {
      path: '/',
    });
  };

  return { token: cookies['token'], setToken, destroySession };
};

export default useSession;
