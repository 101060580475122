import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import useSession from '../hooks/useSession';


const Home = () => {
  const history = useHistory();
  const { token, destroySession } = useSession();

  return (
    <div className="homepage__container">
      <Grid
        className="homepage__hero-grid h-100 w-100"
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="column" alignItems="center" spacing={ 1 }>
            <Grid item>
              <h1 className="homepage__hero-heading">We help you pay your utility bills!</h1>
            </Grid>
            <Grid item className="homepage__hero-subheading">
              <h2>Quick aggregated payments with just a couple clicks</h2>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className="homepage__hero-cta">
          { token ? (
            <>
              <Button
                classes={ { root: "homepage__hero-cta-button" } }
                variant="contained"
                color="primary"
                onClick={ () => history.push(`/dashboard/${token}`) }
              >
                Go to dashboard
              </Button>
              <Button
                classes={ { root: "homepage__hero-cta-button" } }
                variant="outlined"
                color="secondary"
                onClick={ destroySession }
              >
                Forget me
              </Button>
            </>
          ) : (
            <>
              <Button
                classes={ { root: "homepage__hero-cta-button" } }
                variant="contained"
                color="primary"
                onClick={ () => history.push("/quickpay") }
              >
               Quick Pay
              </Button>

              <Button
                classes={ { root: "homepage__hero-cta-button--secondary" } }
                variant="outlined"
                onClick={ () => history.push("/register") }
              >
                Sign Up
              </Button>
            </>
          ) }
        </Grid>
      </Grid>
    </div>
  );
};

export default Home
