import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import useAPI from '../lib/api';
import useSession from '../hooks/useSession';

type Props = {
  anonymous: boolean,
};

type CredentialPairs = {
  [key: string]: {
    username: string,
    password: string,
    timestamp: number,
  },
}

const CredentialsDynamicForm = ({ anonymous }: Props) => {
  const history = useHistory();
  const { createUser } = useAPI();
  const { setToken } = useSession();

  const [ name, setName ] = useState(anonymous ? 'Anonymous' : '');
  const [ credentialPairs, setCredentialPairs ] = useState<CredentialPairs>({
    "0": {
      username: '',
      password: '',
      timestamp: Date.now(),
    }
  });

  const handleCredentialPairAdd = () => {
    setCredentialPairs({
      ...credentialPairs,
      [Object.keys(credentialPairs).length]: {
        username: '',
        password: '',
        timestamp: Date.now(),
      },
    });
  };

  const handleCredentialPairUpdate  = (pairId: string, attribute: string, value: string) => {
    setCredentialPairs({
      ...credentialPairs,
      [pairId]: {
        ...credentialPairs[pairId],
        [attribute]: value,
      },
    });
  };

  const handleRegistration = () => {
    if (!anonymous && !name) {
      // TODO: Visual feedback that a name is required
      return;
    }
    const accounts = Object.keys(credentialPairs).map(pairId => credentialPairs[pairId]);
    createUser(name, accounts)
      .then(response => {
        setToken(response.data.token);
        history.push(`/dashboard/${response.data.token}`)
      });
  };

  return (
    <Grid container direction="column" spacing={ 1 }>
      { !anonymous && (
        <Grid item>
          <Paper className="register__credential-box-wrapper">
            <TextField fullWidth label="Name" type="name" value={ name } onChange={ e => setName(e.target.value) } />
          </Paper>
        </Grid>
      ) }
      { Object.keys(credentialPairs).map(pairId => (
        <Grid item key={ pairId }>
          <Paper className="register__credential-box-wrapper">
            <TextField
              className="w-100"
              autoFocus
              label="Username"
              type="username"
              value={ credentialPairs[pairId].username }
              onChange={ e => handleCredentialPairUpdate(pairId, 'username', e.target.value) }
            />
            <TextField
              className="w-100"
              margin="dense"
              label="Password"
              type="password"
              value={ credentialPairs[pairId].password }
              onChange={ e => handleCredentialPairUpdate(pairId, 'password', e.target.value) }
            />
          </Paper>
        </Grid>
      )) }
      <Grid item>
        <Grid container justifyContent="flex-end" spacing={ 1 }>
          <Grid item>
            <Button id="add-credential-pair" onClick={ handleCredentialPairAdd } color="secondary" variant="outlined" component="span">
              Add more
            </Button>
          </Grid>

          <Grid item>
            <Button onClick={ handleRegistration } color="primary" variant="contained" component="span">
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CredentialsDynamicForm.propTypes = {
  anonymous: propTypes.bool,
};

CredentialsDynamicForm.defaultProps = {
  anonymous: false,
};

export default CredentialsDynamicForm;
