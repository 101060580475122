import { Switch, Route } from 'react-router-dom';

import Home from './screens/Home';
import Dashboard from './screens/Dashboard';
import Register from './screens/Register';
import QuickPay from './screens/QuickPay';

import './scss/init.scss';

const App = () => (
  <Switch>
    <Route path="/dashboard/:identifier">
      <Dashboard />
    </Route>

    <Route path="/quickpay">
      <QuickPay />
    </Route>

    <Route path="/register">
      <Register />
    </Route>

    <Route path="/">
      <Home />
    </Route>
  </Switch>
);

export default App;
