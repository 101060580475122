import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import MonetizationOnOutlined from '@material-ui/icons/MonetizationOnOutlined';
import Schedule from '@material-ui/icons/Schedule';
import CircularProgress from '@material-ui/core/CircularProgress';
import QRCode from 'react-qr-code';

import useAPI from '../lib/api';

import OverviewCard from '../components/OverviewCard';

type DashboardParams = {
  identifier: string,
};

type Account = {
  id: number,
  username: string,
  password: string,
  due_amount: string | null,
  due_date: string | null,
};

type AccountValid = {
  id: number,
  username: string,
  password: string,
  due_amount: string,
  due_date: string,
};

const Dashboard = () => {
  const { identifier } = useParams<DashboardParams>();
  const { createInvoice, getAccounts } = useAPI(identifier);

  const [ accounts, setAccounts ] = useState<Array<Account>>([ ]);
  const [ QRCodeValue, setQRCodeValue ] = useState<string>('');
  const [ paymentValue, setPaymentValue ] = useState<number>(0.00);
  const [ paymentAccounts, setPaymentAccounts ] = useState<Array<number>>([ ]);
  const [ creatingInvoice, setCreatingInvoice ] = useState<boolean>(false);
  const [ totalDue, setTotalDue ] = useState<number>(0);

  useEffect(() => {
     getAccounts()
       .then(response => setAccounts(response.data.cuc_accounts));
  }, [ ]);

  useEffect(() => {
    const totalDue = roundNumber(
      accounts.reduce((acc, current) => (
        acc + parseFloat(current.due_amount || '0.00')
      ), 0.0));

    setTotalDue(totalDue || 0);
  }, [ accounts ]);

  const columns = [
    { field: 'username', headerName: 'Account', flex: 1 },
    {
      field: 'due_amount',
      headerName: 'Total Due',
      flex: 1,
      valueGetter: (params: any) => {
        const dueAmount = params.row.due_amount;
        return dueAmount ? dueAmount : 'N/A';
      },
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      valueGetter: (params: any) => {
        const date = params.row.due_date;

        if (!date) {
          return 'N/A';
        }

        return new Date(date).toLocaleString().split(',')[0]
      },
    },
  ];

  const roundNumber = (number: number) =>  Math.round((number + Number.EPSILON) * 100) / 100;

  const handleAccountSelection = (params: any) => {
    const selectedRows: Array<number> = params;
    let isAddition: boolean;
    let accountId: number;

    if (selectedRows.length > paymentAccounts.length) {
      isAddition = true;
      accountId = selectedRows.filter(x => !paymentAccounts.includes(x))[0];
    }

    else {
      isAddition = false;
      accountId = paymentAccounts.filter(x => !selectedRows.includes(x))[0];
    }

    const validAccounts = accounts.filter(isValidAccount);
    const account = validAccounts.find(a => a.id === accountId)!;

    if (!account) {
      return;
    }

    if (isAddition) {
      setPaymentValue(roundNumber(paymentValue + parseFloat(account.due_amount)));

      const newAccounts = paymentAccounts;
      newAccounts.push(account.id);

      setPaymentAccounts(newAccounts);
    }

    else {
      setPaymentValue(roundNumber(paymentValue - parseFloat(account.due_amount)));
      const newAccounts = paymentAccounts.filter(account => account !== accountId);
      setPaymentAccounts(newAccounts);
    }
  };

  const handleRowIsSelectable = ({ row }: any) => {
    return parseFloat(row.due_amount) ? true : false;
  };

  const isValidAccount = (account: Account): account is AccountValid => {
    return account.due_date !== null && account.due_amount !== null;
  }

  const getEarliestDueDate = () => {
    const filteredAccounts = accounts.filter(isValidAccount);

    if (filteredAccounts.length === 0) {
      return 'N/A';
    }

    const earliest = new Date(
      filteredAccounts
        .sort((a, b) => (
          new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
        ))[0].due_date
    );

    return earliest.toLocaleString().split(',')[0];
  };

  const handleQRGeneration = () => {
    setCreatingInvoice(true);
    createInvoice('BCH', paymentAccounts)
      .then(response => {
        setCreatingInvoice(false);
        setQRCodeValue(response.data.bitcoin_uri)
        // TODO: connect to websocket channel using invoice id
        // to provide real time feedback when the payment is processed
      });
  };

  return (
    <Container maxWidth="md" className="dashboard__container">
      <Grid container direction="column" spacing={ 2 }>

        <Grid item>
          <Grid container justifyContent="center" spacing={ 2 }>
            <Grid item xs={ 4 }>
              <OverviewCard
                icon={ <AccountCircleOutlined /> }
                title="Accounts"
                value={ accounts.length }
              />
            </Grid>

            <Grid item xs={ 4 }>
              <OverviewCard
                icon={ <MonetizationOnOutlined /> }
                title="Total Due"
                value={ '$' + totalDue }
              />
            </Grid>

            <Grid item xs={ 4 }>
              <OverviewCard
                icon={ <Schedule  /> }
                title="Earliest Due Date"
                value={ getEarliestDueDate() }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 8 }>
              { accounts.length > 0 && (
                <DataGrid
                  onSelectionModelChange={ handleAccountSelection }
                  isRowSelectable={ handleRowIsSelectable }
                  checkboxSelection
                  className="h-100 dashboard__accounts-table"
                  rows={ accounts }
                  columns={ columns }
                  pageSize={ 5 }
                />
              ) }
            </Grid>

            <Grid item xs={ 4 }>
              <h2 className="dashboard__payment-heading">Payment QR Code</h2>
              <h4 className="dashboard__payment-total">Total: { '$' + paymentValue }</h4>

              <Grid container justifyContent="center">
                { QRCodeValue
                  ? (
                    <Grid item>
                      <QRCode value={ QRCodeValue } />
                    </Grid>
                  )
                  : (
                    <Grid item className="dashboard__payment-qr-placeholder">
                      <Grid
                        className="h-100"
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={ 2 }
                      >
                        { creatingInvoice ? (
                            <Grid item>
                              <CircularProgress />
                            </Grid>
                          ) : (
                            <>
                              <Grid item>
                                <Button
                                  disabled={ !paymentValue }
                                  onClick={ handleQRGeneration }
                                  variant="outlined"
                                  color="primary"
                                >
                                  Pay
                                </Button>
                              </Grid>
                              <Grid item>
                                <p>(Select at least 1 account)</p>
                              </Grid>
                            </>
                          ) }
                      </Grid>
                    </Grid>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Container>
  );
};

export default Dashboard;
