import axios from 'axios';

const { REACT_APP_BILLPAY_API_URL } = process.env;

const useAPI = authenticationToken => {
  const instance = axios.create({
    baseURL: REACT_APP_BILLPAY_API_URL,
  });

  if (authenticationToken) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${authenticationToken}`;
  }

  const createUser = (name, accounts) =>
    instance.post('/sign-up', {
      name,
      accounts
    });

  const createInvoice = (network, accounts) => 
    instance.post('/invoices', {
      network,
      accounts
    });

  const getAccounts = () => instance.get('/cuc-accounts');

  return { createUser, createInvoice, getAccounts };
};

export default useAPI;
