import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

type Props = {
  icon: React.ReactNode,
  title: string,
  value: string | number,
};

const OverviewCard = ({ icon, title, value }: Props) => {
  return (
    <Paper className="dashboard__overview-card">
      <Grid container justifyContent="center">
        <Grid item xs={ 4 }>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item className="dashboard__overview-card-icon">
              { icon }
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={ 8 }>
          <Grid container direction="column">
            <Grid item>
              <h2 className="dashboard__overview-card-title">{ title }</h2>
            </Grid>

            <Grid item>
              <p className="dashboard__overview-card-value">
                { value }
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OverviewCard;
