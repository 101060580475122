import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import CredentialsDynamicForm from '../components/CredentialsDynamicForm';

const Register = () => {
  return (
    <Container maxWidth="sm" className="register__container">
      <Grid container direction="column" spacing={ 1 }>
        <Grid item>
          <Paper className="register__heading-wrapper">
            <h1>Sign Up</h1>
            <p>
              To get access to simplified payments all you need to do is add all the
              credential username/password pairs you have access to below.
            </p>
          </Paper>
        </Grid>

        <Grid item className="w-100">
          <CredentialsDynamicForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Register;
