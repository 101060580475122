import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import CredentialsDynamicForm from '../components/CredentialsDynamicForm';

const QuickPay = () => {
  return (
    <Container maxWidth="sm" className="quickpay__container">
      <Grid container direction="column" spacing={ 1 }>
        <Grid item>
          <Paper className="register__heading-wrapper">
            <h1>Quick Pay</h1>
            <p>
              Insert credential username/password pairs below to quickly generate an aggregated payment QR code.
            </p>
          </Paper>
        </Grid>
        <Grid item>
          <CredentialsDynamicForm anonymous />
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuickPay;
